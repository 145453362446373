<template>
    <v-container>
        <v-simple-table class="text-center">
            <thead>
                <tr>
                    <th>Agent</th>
                    <th>Est. Contract Value</th>
                    <th>Est. Declared Commission</th>
                    <th>Est. Declared Commission Value</th>
                    <th>Est. Live Commission</th>
                    <th>Est. Live Commission Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <v-avatar
                        color="primary"
                        size="36"
                        >
                        <v-icon dark>
                            mdi-account-circle
                        </v-icon>
                        </v-avatar>
                    </td>
                    <td>£{{ lgValue}}</td>
                    <td>{{ lgDeclared}}%</td>
                    <td>£{{ lgDeclaredValue}}</td>
                    <td>{{ lgLive}}%</td>
                    <td>£{{ lgLiveValue}}</td>
                </tr>
                <tr>
                    <td>
                        <v-avatar
                        color="primary"
                        size="36"
                        >
                            <v-icon dark>
                                mdi-account-circle
                            </v-icon>
                        </v-avatar>
                    </td>
                    <td>£{{ clValue}}</td>
                    <td>{{ clDeclared}}%</td>
                    <td>£{{ clDeclaredValue}}</td>
                    <td>{{ clLive}}%</td>
                    <td>£{{ clLiveValue}}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-container>
</template>
<script>
export default {
props: {
    lgValue: {
      type: Number,
      default: 0
    },
    lgDeclared: {
      type: Number,
      default: 0
    },
    lgDeclaredValue: {
      type: Number,
      default: 0
    },
     lgLive: {
      type: Number,
      default: 0
    },
     lgLiveValue: {
      type: Number,
      default: 0
    },
     clValue: {
      type: Number,
      default: 0
    },
    clDeclared: {
      type: Number,
      default: 0
    },
    clDeclaredValue: {
      type: Number,
      default: 0
    },
     clLive: {
      type: Number,
      default: 0
    },
     clLiveValue: {
      type: Number,
      default: 0
    },
}
} 
</script>